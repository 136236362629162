



















import {Component, Vue} from "vue-property-decorator";
import ConstantTool from "@/services/tool/ConstantTool";
import LangModule from "@/store/LangModule";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import CompanyModule from "@/store/CompanyModule";
import ChangePasswordDialog from "@/components/ChangePasswordDialog.vue";
import BreadcrumbsModule from "@/store/BreadcrumbsModule";
import ConfigModule from "@/store/ConfigModule";
import NotificationsModule from "@/store/NotificationsModule";

@Component({components:{ChangePasswordDialog}})
export default class HomeView extends Vue {

	sessionModule: SessionModule = getModule(SessionModule)
	projectName: string = ConstantTool.PROJECT_NAME
	langModule: LangModule = getModule(LangModule)
}
