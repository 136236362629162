




































import {Component, Ref, Vue} from "vue-property-decorator";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import UserService from "@/services/UserService";
import SessionModule from "@/store/SessionModule";

@Component
export default class ChangePasswordDialog extends Vue {
    lang: any = getModule(LangModule).lang
    @Ref() readonly form!: HTMLFormElement
    sessionModule: SessionModule = getModule(SessionModule)
    changePasswordDialog: boolean = false
    password: string = ""
    newPassword: string = ""
    repeatPassword: string = ""
    showPassword: boolean = false
    showNewPassword: boolean = false
    newPasswordRules = [
        (v: string) => v.length > 0 ? true : this.lang.newPasswordRequired,
        (v: string) => v.length >= 4 ? true : this.lang.password4CharactersRequired
    ]

    created() {
        if (this.sessionModule.session.user!.notifyPasswordChange!) {
            this.changePasswordDialog = true
            UserService.changePasswordWasNotified(this)
        }
    }

    changePassword() {
        if (this.form.validate()) {
            getModule(DialogModule).showDialog(new Dialog(
                this.lang.warning,
                this.lang.passwordUpdateQuestion,
                () => UserService.patchChangePassword(this, this.password, this.newPassword, this.sessionModule.session.user!.id!)
            ))
        }
    }
}
